.divVideo {
    position: relative;
    max-height: 600px;
    overflow: hidden;
    background-color: black;
    display: flex;
    justify-content: center;
}

.video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.playButton, .muteButton {
    position: absolute;
    color: white;
    font-size: 2rem; /* Ajuste o tamanho conforme necessário */
    cursor: pointer;
    z-index: 2; /* Garante que os botões fiquem acima do vídeo */
}

.playButton {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.muteButton {
    top: 10px; /* Posiciona no canto superior direito */
    right: 10px;
    z-index:0;
}



