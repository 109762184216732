.paragrafoMostrarMais{
    display: flex;
    flex-direction: column;
    color:rgb(44, 44, 44);
    white-space: pre-line
}

.paragrafoMostrarMaisBlack{
    display: flex;
    flex-direction: column;
    color:rgb(0, 0, 0);
    white-space: pre-line
}

.botaoMostrarMais{
    background-color: white;
    border:1px solid grey;
    border-radius: 10px;
    color:grey;
    width: 50px;
    margin-top: 5px;
    cursor: pointer;
}