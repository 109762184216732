
.searchModal { 
    position: absolute;
    left: calc( 50% - 300px);
    top: calc( 50% - 250px);
    background-color: white;
    width: 600px;
    height: 500px;
    z-index: 2;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgb(165, 164, 164);
    z-index: 5;
    padding: 7px;
}
.fadeBackground{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.521);
    z-index: 5;
}

.searchModalTitle { 
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 25px;
}

.searchModalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
}

.searchModalInput {
    height: 40px;
    font-size: 1.12em;
    border-radius: 5px;
    flex: 1 1 80%;
}

.searchModalButton {
    flex: 1 1 20%;
    cursor: pointer;
    border:1px solid rgba(128, 128, 128, 0.76);
    background-color: #ffffff; /* Fundo branco */
    color: #333333; /* Cor do texto em cinza escuro */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 16px; /* Tamanho da fonte */
    border-radius: 4px; /* Bordas levemente arredondadas */
    transition: background-color 0.3s, color 0.3s; /* Efeito de transição */
}

.searchModalButton:hover{
    background-color: #e9f9fc;
}

.searchModalButton:active{
    background-color: #aedaf3;
}



.results { 
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.results p {
    text-align: center;
    font-size: 1em;
    color: #333;
}

.iconClose {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}



@media only screen and (max-width: 650px) { /*ENTRANDO NO MOBILE, MENU MUDA PRA HORIZONTAL*/
    .searchModal{
        left:0;
        top:0;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }
  
}