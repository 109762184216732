
.container {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width:370px;
    z-index:7; /*tem prioridade aos outros modais*/
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding:13px;
    box-shadow: 0px 0px 5px black;
    max-height: 98vh;
    overflow-y: auto;
  }
  .containerDenuncia{
    max-width: 400px;
    padding:5px;
  }
  .botaoDenunciar {
    padding: 10px 20px; /* Adiciona um pouco mais de padding para um botão mais robusto */
    background-color: rgb(245, 31, 31); /* Cor de fundo vermelha */
    border: none; /* Remove a borda padrão */
    color: white; /* Cor do texto branco */
    font-size: 16px; /* Tamanho da fonte */
    font-weight: bold; /* Texto em negrito */
    border-radius: 5px; /* Cantos arredondados */
    cursor: pointer; /* Indica que é clicável */
    transition: background-color 0.3s; /* Transição suave para efeitos de hover, se desejar adicionar depois */
  }

  .botaoCancelar {
    padding: 10px 20px; /* Mesma dimensão do botão de denúncia */
    background-color: rgb(0, 153, 76); /* Cor de fundo verde */
    border: none; /* Remove a borda padrão */
    color: white; /* Cor do texto branco */
    font-size: 16px; /* Tamanho da fonte */
    font-weight: bold; /* Texto em negrito */
    border-radius: 5px; /* Cantos arredondados */
    cursor: pointer; /* Indica que é clicável */
    transition: background-color 0.3s; /* Transição suave para efeitos de hover, se desejar adicionar depois */
  }
  .groupButton{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .titulo{
    display: flex;
    font-weight: bold;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
  .corpoDenuncia{
    margin-bottom: 20px;
  }
  .title{
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
  .icon{
    cursor: pointer;
  }

  .fadeBackground{
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    position: fixed;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.171);
  }

  .ulOpcoes{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .ulOpcoes li {
    padding: 13px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5; /* Fundo padrão */
    margin-bottom: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Animação suave */
  }
  
  .ulOpcoes li:hover {
    background-color: #e0e0e0; /* Fundo ao passar o mouse */
    transform: translateY(-2px); /* Efeito de "levitar" */
  }
  
  

  .redOption{
    color:red;
  }

  .reasonContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }





  .ulDenuncia{
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .ulDenuncia li {
    padding: 13px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: #f5f5f5; /* Fundo padrão */
    margin-bottom: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Animação suave */
  }
  
  .ulDenuncia li:hover {
    background-color: #e0e0e0; /* Fundo ao passar o mouse */
    transform: translateY(-2px); /* Efeito de "levitar" */
  }
  
  