*{
  box-sizing: border-box
}
.container {
  z-index: 5;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}

.fadeBackground {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.562);
  z-index: 1;
}

.modal {
  z-index: 2;
  background-color: white;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.247);
}
.cursorPointer{
  cursor: pointer;
}
.containerUsuarios{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.userProfile{
  margin-bottom: 15px;
}
.botaoRemover{
  height: 100%;
}
.botaoRemover button{
  height: 100%;
  padding:7px;
  background-color: #6259d8;
  border: 0px;
  color:white;
  border-radius: 3px;
  cursor: pointer;
}

.conteudo {
  padding: 7px;
}
.pesquisa{
  display: flex;
  justify-content: flex-start;
  gap:5px;
  margin-bottom: 10px;
}

.titleContainer{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(117, 117, 117, 0.507);
  padding: 10px;

}

.title{
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
}
.conteudo{
  overflow-y: scroll; /* Rolagem vertical */
  height: 350px;

}

.searchInput{
  width: 100%;
}

.searchButton {
  padding: 10px 15px;
  background-color: #6259d8; /* Cor verde */
  color: white; /* Texto branco */
  border: none; /* Remove a borda padrão */
  border-radius: 5px; /* Bordas arredondadas */
  cursor: pointer; /* Ícone de cursor ao passar o mouse */
  font-size: 16px; /* Aumenta o tamanho da fonte */
  transition: background-color 0.3s ease; /* Efeito de transição suave */
}

.searchButton:hover {
  background-color: #534cb8; /* Cor verde */
}


@media (max-width: 400px) {
  .modal{
    height: 100%;
    border-radius: 0;
  }
}