.menuLateral {
    grid-area: espaco-esquerdo;
    position: fixed;
    height: 100vh;
    width: 250px;
    border: 1px solid rgb(214, 214, 214);
    padding: 30px 10px 30px 10px;
    background-color: #fff;
    z-index:3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuLateralShort{
    /*grid-area: espaco-esquerdo;*/
    position: fixed;
    height: 100vh;
    width: 70px;
    border: 1px solid rgb(214, 214, 214);
    padding: 30px 10px 30px 10px;
    background-color: white;
    z-index:4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuLateralBlackShort{
    /*grid-area: espaco-esquerdo;*/
    position: fixed;
    height: 100vh;
    width: 70px;
    border: 1px solid rgb(214, 214, 214);
    padding: 30px 10px 30px 10px;
    background-color: black;
    z-index:4;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/*.goBack{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}*/
.NotificacaoNumero{
    background-color: red;
    font-size: 14px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    position: absolute;
    left: 25px;
    top:4px;
}
.NotificacaoNumeroShort{
    background-color: red;
    font-size: 14px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    position: absolute;
    left: 25px;
    top:4px;
}

.divIconShort{
    position: relative;
    width:100%;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 17px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
}
.hevelim{
    font-family: 'Poppins';
    font-size: 1.5em;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}
/* Menu Icons */
.divIcon {
    position: relative;
    width: 200px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 17px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;
}
.divIcon p {
    font-size: 14px;
}

.divIcon:hover {
    background-color: rgba(119, 119, 119, 0.116);
}

.icon {
    font-size: 25px;
}

.fadeBackground {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    top: 0;
    left: 0;
}

.fadeBackgroundNoColor {
    position: fixed;
    height: 100vh;
    width: calc(100vw - 70px); /*Deixa 70px livre, q é o tamanho do menú short*/
    left: 70px; /*E leva os 70px livre pra esquerda, q é onde fica o menú short*/
    /*background-color: rgba(0, 0, 0, 0.5);*/
    background-color: rgba(0, 0, 0, 0); 
    z-index: 2;
    top: 0;
}

.profileIcon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%; /* Transformar em círculo */
    overflow: hidden; /* Garante que a imagem não ultrapasse os limites */
    object-fit: cover; /* Centraliza e ajusta a imagem dentro do círculo */
    border: 2px solid #ddd; /* Opcional: borda leve ao redor da imagem */
}

.profileIcon img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem se ajuste bem ao círculo */
}

.menuTopo{
    display: none;
}

@media only screen and (max-width: 650px) {
    .menuLateralShort{
        height: 38px; /* Msm tamanho do menu do topo, pro shorts ficar centralizado*/
        width: 100vw;
        border: 1px solid rgb(214, 214, 214);
        padding: 0;
        background-color: #fff;
        z-index:3;
        display: flex;
        flex-direction: row;
        border-left: 0;
        border-right: 0;
        bottom: 0;
    }
    
    .divIconShort{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:0;
    }
    .menuTopo{
        display: block;
        position: fixed;
        top:0;
        left: 0;
        width: 100vw;
    }

}