/* Container principal */
.container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 250px 8fr 3fr;
    grid-template-areas: "espaco-esquerdo espaco_central espaco-direito";
    overflow: auto;
    background-color: rgb(255, 255, 255);
    padding-bottom: 70px;

}

.materialItem{
  display: flex;
  width: 100%;
}
.exibidorDeVideo{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.containerVerificarPerfil {
  background-color: #f9f9f9; /* Fundo suave para destaque */
  border-radius: 10px; /* Bordas arredondadas para um visual moderno */
  padding: 20px; /* Espaçamento interno */
  max-width: 400px; /* Largura máxima para centralizar o conteúdo */
  margin: 20px auto; /* Centraliza horizontalmente na página */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidade */
  font-family: 'Arial', sans-serif; /* Fonte limpa e fácil de ler */
}
.containerVerificarPerfil p::before {
  content: '✔ '; /* Adiciona um ícone de check para todos os parágrafos */
  color: green; /* Cor verde para indicar confirmação/benefício */
}

.containerVerificarPerfil p {
  color: #333; /* Cor de texto legível */
  font-size: 16px; /* Tamanho de texto para boa leitura */
  line-height: 1.5; /* Espaçamento entre as linhas */
}

.botaoExibir {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: transparent; /* Fundo transparente */
  color: black; /* Cor do texto */
  border: 1px solid black; /* Borda fina */
  cursor: pointer; /* Indica que é clicável */
  font-size: 16px; /* Tamanho da fonte */
  border-radius: 4px; /* Bordas arredondadas */
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease; /* Transição suave */
}

.exibicoes{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.matVideo{
  max-width: 100%;
  max-height: 700px;
}

.marcacao{
  background-color: rgb(218, 218, 218);
  max-width: 200px;
  margin-bottom: 15px;
}

/* Espaço esquerdo (Menu lateral) */
.espaco_esquerdo {
    grid-area: espaco-esquerdo;
    max-width: 100%;
}

/* Espaço central (Área do conteúdo principal) */
.espaco_central {
    grid-area: espaco_central;
    display: flex;
    justify-content: center;
}

/* Área de postagens */
.postagens {
    display: flex;
    flex-direction: column;
    width: 468px; 
    
}

/* Espaço direito (Seção à direita) */
.espaco_direito {
    grid-area: espaco-direito;
    display: flex;
    justify-content: flex-start;
    padding: 40px 0 0 0;
    flex-direction: column;
}

.captulo{
    font-weight: bold;
    margin-bottom: 20px;
}

.corpoBiblia {
    font-family: 'Merriweather', Georgia, 'Times New Roman', serif;
    font-size: 18px; /* Tamanho ajustável para leitura confortável */
    line-height: 1.8; /* Melhor espaçamento entre linhas */
    color: #333; /* Tom agradável para leitura, não muito preto */
}


.chapterButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 25px;
  }
  
  .chapterButton {
    padding: 10px 15px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chapterButton:hover {
    background-color: #e0e0e0;
  }
  
  .chapterInput {
    width: 50px;
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }


  .recuperacao {
    font-size: 1rem; /* Tamanho da fonte legível */
    color: #2c3e50; /* Tom escuro para facilitar a leitura */
    background-color: #eafaf1; /* Fundo com tom suave e tranquilo */
    border-left: 4px solid #27ae60; /* Barra lateral destacando a mensagem */
    padding: 10px 15px; /* Espaçamento interno para conforto visual */
    border-radius: 5px; /* Arredondar as bordas */
    font-family: 'Arial', sans-serif; /* Fonte clara e comum */
    margin: 15px 0; /* Espaçamento externo */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve para destaque */
  }

  .verse{
    display: flex;
    flex-direction: row;
    gap: 4px;
    text-align: justify;
  }
  .explanation {
    text-align: justify;
    margin-left: 37px;
    font-size: 1rem; /* Tamanho da fonte para as explicações */
    color: #333; /* Cor de texto mais suave para as explicações */
    line-height: 1.6; /* Melhor legibilidade entre linhas */
    border-left: 4px solid #5b5b5b; /* Adicionando uma borda à esquerda para destacar a explicação */
    padding-left: 15px; /* Distância entre a borda e o texto */
    margin-bottom: 20px; /* Espaço entre a explicação e o próximo conteúdo */
  }
  
  .explanation strong {
    font-weight: bold; /* Destacando o título da explicação */
    color: #2c3e50; /* Cor um pouco mais escura para o título */
  }
  
  .explanation p {
    margin: 5px 0; /* Espaço entre os parágrafos dentro da explicação */
  }

  .toggleButton {
    margin-left: 10px;
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 1rem;
    transition: color 0.2s ease;
  }
  
  .toggleButton:hover {
    color: #0056b3;
  }
  .spanVerse{
    font-weight: bold;
    color: rgb(136, 135, 135);
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
  }
 
  
  .marcarLido {
    display: flex;
    align-items: center; /* Alinha o ícone e o texto verticalmente no centro */
    background-color: #f2f2f2; /* Cor de fundo suave para o botão */
    padding: 10px 20px; /* Espaçamento interno para o botão */
    border-radius: 8px; /* Bordas arredondadas para suavizar o visual */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Sombrinha suave */
    cursor: pointer; /* Indicador de que é clicável */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transições suaves */
    width: fit-content;
    max-width: 250px; /* Largura máxima para que não fique muito largo */
    margin: 10px 0; /* Margem superior e inferior */
  }
  
  .marcarLido p {
    font-size: 16px; /* Tamanho da fonte */
    font-weight: bold; /* Deixa o texto mais forte */
    color: #333; /* Cor do texto */
    margin-right: 10px; /* Espaçamento entre o texto e o ícone */
  }











  /* Estilos para telas menores (mobile) */
@media only screen and (max-width: 1120px) { /*Monitores com telas menores e tablets grandes*/
    .container{
      grid-template-columns: 250px 1fr;
      grid-template-areas: "espaco-esquerdo espaco_central";
    }
    .espaco_direito {
      display: none; /* Esconde o espaco_direito */
    }
}

@media only screen and (max-width: 880px) { /*VERSÃO TABLET MENOR*/
  /*
   ***Não remover esta guia, os comentários são importantes***
   (FEITO) Postagem unica exibe só comentário agora e 100% da tela.
  */
  .container{
    grid-template-columns: 70px 1fr; /*Menu short ocupa 70px apenas*/
    grid-template-areas: "espaco-esquerdo espaco_central";
  }
  .espaco_direito {
    display: none; /* Esconde o espaco_direito */
  }
}

@media only screen and (max-width: 650px) { /*ENTRANDO NO MOBILE, MENU MUDA PRA HORIZONTAL*/
  /*
  ***Não remover esta guia, os comentários são importantes***
  (FEITO) Painel ocupam 100% da tela. Exemplo de painéis: pesquisa, notificacao;
  (FEITO) Modais criar postagem e criar shorts agora são tela inteira também
  (FEITO) Menú agora não é mais lateral, nem lateral estilo short, e sim horizontal.
  */
  .container{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: "espaco_central"; /*N precisa do espaço esquerdo pq ele fica em cima do central (fixed)*/
  }
  .postagens{
    margin-top: 25px; /*Pro menu top n ficar em cima*/
  }

  .espaco_esquerdo{
      position: fixed; /* Fixa o menu na parte inferior */
      bottom: 50px;
      width: 100vw; /* Ocupa toda a largura da tela */
      z-index:2;
  }
  /*Dentro do Menu: Caso < 580 vira horizontal*/

}
@media only screen and (max-width: 480px) { /*Mobile pequeno, ocupa tela toda nas postagens*/
  /*(FEITO) Postagens mudou pra ocupar 100% da tela */
  .postagens{
      width: 100%;
      padding: 10px;
  }
}

