/* index.module.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
  }
  
  .content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .atualizarContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px; /* Reduzir o padding */
    border: none; /* Remove a borda padrão */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho da fonte */
}
.atualizarSpan{
  cursor: pointer; /* Cursor de mão ao passar o mouse */
  background-color: #007bff; /* Cor de fundo do botão */
  padding:10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Sombra para o botão */
  color:white;
}



/* Para centralizar dentro de um container */
.atualizarIcon {
    margin: 0 auto; /* Centraliza horizontalmente */
}
  .voltar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #007bff;
    margin-bottom: 10px;
    font-weight: bold;
    width: 65px;
    cursor: pointer;
  }
  
  .infoText {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .email {
    font-weight: bold;
    color: #007bff;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .label {
    font-size: 16px;
    color: #555;
  }
  
  .input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .button {
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  .buttonDisabled{
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #8d8d8d;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .successMessage {
    margin-top: 10px;
    font-size: 14px;
    color: #28a745;
  }
  