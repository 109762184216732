
.container{
    background-color: white;
    position: absolute;
    height:270px;
    width: 350px;
    z-index: 20;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.flexRow{
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.pf{
  color: rgb(86, 247, 120);
}
.containerUpper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:20px 20px 5px 20px;
}
.contaimerMiddle{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.atributesContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userImage {
    width: 70px;
    height: 70px;
    border-radius: 50%; /* Faz a imagem ficar redonda */
    object-fit: cover;  /* Garante que a imagem não fique esticada e mantenha a proporção */
    border: 2px solid #ddd; /* Adiciona uma borda leve ao redor da imagem */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra leve para um efeito mais legal */
    cursor:pointer;
  }

  .nameAndusername{
    margin-left:10px;
    cursor:pointer;
  }
  .name{
    font-weight: bold;
  }