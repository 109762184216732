
.modalExcluirBanir{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

  
.modalParteUsada{
    max-width: 400px;
    background-color: #f8f9fa;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 15px;
}

.mensagemParagrafo {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    padding: 15px;
    text-align: justify;
  }

  .modalParteUsada button{
    padding:10px;
    min-width: 70px;
    margin-top: 20px;
    cursor: pointer;
    margin-top: 10px;

  }

  .spanBotoes{
    display: flex;
    justify-content: space-between;
  }

  .botaoCancelarExclusao {
    background-color: #28a745; /* Cor de fundo verde */
    color: white; /* Texto branco */
    border: none; /* Remove a borda padrão */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 16px; /* Tamanho da fonte */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    transition: background-color 0.3s ease; /* Animação suave ao passar o mouse */
  }
  
  .botaoCancelarExclusao:hover {
    background-color: #218838; /* Cor de fundo mais escura ao passar o mouse */
  }
  
  .botaoCancelarExclusao:focus {
    outline: none; /* Remove o contorno padrão ao focar */
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5); /* Adiciona uma sombra verde ao focar */
  }


  .sairBotao {
    background-color: #dc3545; /* Cor de fundo vermelha */
    color: white; /* Texto branco */
    border: none; /* Remove a borda padrão */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 16px; /* Tamanho da fonte */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    transition: background-color 0.3s ease; /* Animação suave ao passar o mouse */
  }
  
  .sairBotao:hover {
    background-color: #c82333; /* Cor de fundo mais escura ao passar o mouse */
  }
  
  .sairBotao:focus {
    outline: none; /* Remove o contorno padrão ao focar */
    box-shadow: 0 0 5px rgba(220, 53, 69, 0.5); /* Adiciona uma sombra vermelha ao focar */
  }