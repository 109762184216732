.container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 70px 1fr;
    grid-template-areas: "espaco-esquerdo espaco_central";
    background-color: rgb(255, 255, 255);
}

.espaco_esquerdo{
    grid-area: espaco-esquerdo; /*PS: Tamanho do menu lateral é exatamente 250px, q é definido no grid*/
}
.mensagensAntigas{
  text-align: center;
  cursor: pointer;
  color: blue;
}

.espaco_central {
    grid-area: espaco_central;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.chatsExistentes{
  flex: 0 0 350px;
  display: flex;  
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.685);
  position: relative;
  padding-top: 40px; /*para n ficar em cima do + de add chats nem no menu top do mobile*/
}


.chatSelected {
  background-color: rgb(223, 248, 234); /* Cor de fundo */
  border-radius: 10px; /* Bordas arredondadas */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Transições suaves */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  margin-left: 3px;
  cursor: pointer;
  padding: 15px;
}

.chatNotSelected{
  border-radius: 10px; /* Bordas arredondadas */
  padding: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Transições suaves */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  cursor: pointer;
}

.notReadMessage {
  border-radius: 10px; /* Bordas arredondadas */
  padding: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Transições suaves */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  margin-left: 3px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 5px 0 1px rgba(110, 248, 68, 0.527);  /* Brilho suave do lado direito */
}



/* Efeito de hover para quando o usuário passa o mouse sobre o chat selecionado */
.chatSelected:hover {
  transform: scale(1.03); /* Um pequeno zoom para indicar interatividade */
}
.chatNotSelected:hover{
  transform: scale(1.03); /* Um pequeno zoom para indicar interatividade */
}
.introMensagens{
  border-radius: 8px;
  padding: 16px;
  text-align: center; /* Centraliza o texto */
  max-width: 400px;
  margin: 0 auto 20px auto; /* Centraliza e adiciona espaço abaixo */
  color: #333; /* Cor do texto */
  font-family: Arial, sans-serif;

   /* Centralização */
   position: absolute;
   top: 40%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.introMensagens p {
  margin: 8px 0;
  font-size: 16px;
  line-height: 1.5;
}
.novoChat {
  background-color: #4CAF50; /* Cor verde vibrante */
  color: white; /* Texto branco */
  padding: 12px 24px; /* Espaçamento interno */
  font-size: 16px; /* Tamanho da fonte */
  font-weight: bold; /* Deixar o texto em negrito */
  border: none; /* Sem borda */
  border-radius: 8px; /* Bordas arredondadas */
  cursor: pointer; /* Cursor de mãozinha ao passar o mouse */
  transition: background-color 0.3s, transform 0.2s; /* Efeitos de transição */
}
.noMessages {
  text-align: center;
  color: #888; /* Cor suave */
  font-size: 16px;
  font-style: italic;
  margin-top: 20px;
}
.relative{
  position: relative;
}
.pAbsoluto{
  position: absolute;
  top:0;
  right: 0;
}
.trashIcon{
  color: grey;
  font-size: 20px;
}
.trashIcon:hover{
  color: grey;
  font-size: 27px;
}
.conversas{
  position: relative;
  width: 100%;
  padding-bottom: 100px;
}
.semMensagens{
  display: flex;
  align-items: flex-start;
  justify-self: center;
}
.allMensagens{
  flex: 1 1 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
}
.chatCabecalho{
  position: sticky;
  top:0;
  z-index: 2;
  background-color: white;
  border: 1px solid rgba(128, 128, 128, 0.322);
  width: 100%;
  /* padding: 5px; */
}
.flexRow{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}
.inicieConversa{
  text-align: center;
}
.flexCabecalho{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
}
.threeDots{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
}
.dropdownMenu {
  position: absolute;
  top: 100%; /* Posiciona o menu abaixo dos três pontos */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
}
.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdownMenu li {
  padding: 5px 10px;
  cursor: pointer;
}
.dropdownMenu li:hover {
  background-color: #f0f0f0;
}
.inputMensagem {
  position: sticky;
  bottom: 0;
  width: 100%;
  resize: none; /* Corrigido o valor, sem aspas */
  overflow-y: auto;
  height: 40px;
  font-size: 17px; /* Aumenta o tamanho do texto e do cursor */
  line-height: 35px; /* Alinha o texto verticalmente */
  padding: 0 5px;
  border-radius: 0px;
  color:rgb(92, 92, 92);
}

.iconeNewChat{
  position: absolute;
  right: 5px;
  top:5px;
  z-index: 4;
}
.plusIcon{
  border: 1px solid rgb(117, 117, 117);
  border-radius: 10px;
  color: grey;
  cursor: pointer;
}
.printMensagemContainer{
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px 40px;
}
.remetenteContainer{
  display: flex;
  justify-content: flex-end;
}
.remetenteMensagem{
  width: auto;
  padding: 10px;
  background-color: rgb(177, 252, 236);
  border-radius: 10px;
  color: rgb(10, 10, 10);
  max-width: 40%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-end;
}
.dataEnvioCss{
  font-size: 0.8em;
  color: rgba(128, 128, 128, 0.945);
}
.destinatarioContainer{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 12px;
}

.iconsSeen{
  font-size: 0.6em;
}
.imgPerfil{
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Faz a imagem ficar redonda */
  object-fit: cover;  /* Garante que a imagem não fique esticada e mantenha a proporção */
  border: 2px solid #ddd; /* Adiciona uma borda leve ao redor da imagem */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra leve para um efeito mais legal */
  cursor:pointer;
}
.destinatarioMensagem{
  width: auto;
  padding: 10px;
  background-color: rgb(232, 233, 233);
  border-radius: 10px;
  color: rgb(10, 10, 10);
  max-width: 40%;
  position: relative;
}
.destinatarioMensagem::after { /*Criando pontinha no chat*/
  content: '';
  position: absolute;
  top: 50%; /* Centraliza a pontinha no meio vertical da div */
  left: -10px; /* Posição à esquerda da div */
  border-top: 10px solid transparent; /* Borda superior transparente */
  border-bottom: 10px solid transparent; /* Borda inferior transparente */
  border-right: 10px solid rgb(232, 233, 233); /* Cor da pontinha, mesclando com o fundo da div */
  transform: translateY(-50%); /* Ajuste para centralizar verticalmente */
}
.seta{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}



@media only screen and (max-width: 1120px) { /*VERSÃO MOBILE + TABLET MENOR AO MSM TEMPO*/
  /*
   ***Não remover esta guia, os comentários são importantes***
   (FEITO) Postagem unica exibe só comentário agora e 100% da tela.
  */
  .container{
    grid-template-columns: 70px 1fr; /*Menu short ocupa 70px apenas*/
    grid-template-areas: "espaco-esquerdo espaco_central";
  }
  .espaco_direito {
    display: none; /* Esconde o espaco_direito */
  }
}


@media only screen and (max-width: 850px) { /*SOME PARTE CENTRAL DO CHAT E PASSA A SER GERENCIADO POR USESTATE*/
 .chatsExistentes{
    flex: 0 0 100%;
 }
}

@media only screen and (max-width: 650px) { /*ENTRANDO NO MOBILE, MENU MUDA PRA HORIZONTAL*/
  /*
  ***Não remover esta guia, os comentários são importantes***
  (FEITO) Painel ocupam 100% da tela. Exemplo de painéis: pesquisa, notificacao;
  (FEITO) Modais criar postagem e criar shorts agora são tela inteira também
  (FEITO) Menú agora não é mais lateral, nem lateral estilo short, e sim horizontal.
  */
  .container{
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: "espaco_central"; /*N precisa do espaço esquerdo pq ele fica em cima do central (fixed)*/
  }
  .espaco_esquerdo{
      position: fixed; /* Fixa o menu na parte inferior */
      bottom: 50px;
      width: 100vw; /* Ocupa toda a largura da tela */
      z-index:2;
  }
  /*Dentro do Menu: Caso < 580 vira horizontal*/

}