
.searchModal { 
    position: absolute;
    background-color: white;
    width: 400px;
    height: 100vh;
    margin-left: 70px; /* Ajusta a posição em relação ao menu lateral */
    z-index: 2;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 2px 2px 10px rgb(165, 164, 164);
}

.searchModalTitle { 
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 25px;
}


.searchModalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
}

.searchModalInput {
    height: 40px;
    font-size: 1.12em;
    border-radius: 5px;
    flex: 1 1 100%;
  
}

.searchModalButton {
    flex: 1 1 100%;
    cursor: pointer;
    border:1px solid rgba(128, 128, 128, 0.76);
    background-color: #ffffff; /* Fundo branco */
    color: #333333; /* Cor do texto em cinza escuro */
    /* padding: 10px 20px;  */
    font-size: 16px; /* Tamanho da fonte */
    border-radius: 4px; /* Bordas levemente arredondadas */
    transition: background-color 0.3s, color 0.3s; /* Efeito de transição */
    white-space: nowrap; /* Impede quebra de texto no botão */
}

.searchModalButton:hover{
    background-color: #e9f9fc;
}

.searchModalButton:active{
    background-color: #aedaf3;
}



.results { 
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.results p {
    text-align: center;
    font-size: 1em;
    color: #333;
}

.iconVoltar {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 580px) {
    .searchModal { 
        position: fixed;
        width: 100vw;
        height: 100vh;
        margin: 0;
        z-index: 3;
        top: 0;
        left: 0;
        padding: 30px;
    }
}
