* {
  box-sizing: border-box;
}
p {
  font-family: 'Montserrat', 'Raleway', 'Lobster', 'Playfair Display', 'Poppins', 'Oswald', 'Roboto', 'Pacifico', sans-serif;
}

/* Estilos para o container principal */
.loginBox {
  font-family: Arial, sans-serif;
  height: 100vh;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow-y: auto;
}

.loginBoxContainer {
  width: 350px;
}

/* Estilos para o lado esquerdo */
.espacoEsquerdo {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  padding: 70px;
}

.espacoEsquerdo .introduction {
  margin-bottom: 20px; /* Espaço abaixo da introdução */
}

.espacoEsquerdo .introduction h1 {
  font-family: 'Lobster', 'Playfair Display', 'Montserrat', 'Raleway', sans-serif;
  font-size: 48px; /* ajuste conforme necessário */
  font-weight: 700;
}

.espacoEsquerdo .introduction p {
  text-align: justify;
  font-size: 22px;
  color: rgb(39, 39, 39);
  line-height: 32px;
}

.espacoEsquerdo .containerAllImgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
  opacity: 0.9;
  justify-content: space-between;
}

.espacoEsquerdo .containerAllImgs .imgContainer {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid orange;
}

.espacoEsquerdo .containerAllImgs .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Estilos para o lado direito (form de login) */
.espacoDireito {
  flex: 1 2 auto;
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para dispositivos menores (mobile) */
@media (max-width: 1322px) {
  .espacoEsquerdo {
    padding: 40px;
  }

  .espacoDireito {
    padding: 40px;
  }
}

@media (max-width: 1200px) {
  .espacoEsquerdo {
    padding: 40px 10px 40px 30px;
  }

  .espacoDireito {
    padding: 40px 0 40px 0px;
  }
}

@media (max-width: 1083px) {
  .espacoEsquerdo {
    padding: 40px 0 40px 20px;
  }

  .espacoDireito {
    padding: 40px 0 40px 0px;
  }
}

@media (max-width: 1067px) {
  .espacoDireito {
    width: 100%;
  }

  .loginBoxContainer {
    width: 400px;
  }

  .espacoEsquerdo {
    padding: 40px;
  }

  .espacoEsquerdo .containerAllImgs {
    justify-content: flex-start;
  }
}

@media (max-width: 755px) {
  .loginBoxContainer {
    width: 100%;
  }

  .espacoEsquerdo {
    padding: 20px;
    flex: 1 1 100%;
  }

  .espacoEsquerdo .containerAllImgs {
    justify-content: center;
    gap:20px;
    
  }

  .espacoDireito {
    padding: 20px;
  }
}


@media (max-width: 410px) {
  .espacoEsquerdo .containerAllImgs {
    gap:10px;
  }
  .espacoEsquerdo{
    padding:10px;
  }

  .espacoDireito{
    padding: 10px;
  }
}
