*{
    box-sizing: border-box;
}
.modalPostagem {
    position: fixed;
    z-index: 6;
    top: calc(50vh - 225px);
    right: calc(50vw - 275px);
    width: 550px;
    height: 450px;
    box-shadow: 0 0 10px black;
    background-color: white;
    border-radius: 3px;
    overflow: auto; /* Permite rolagem */
    scrollbar-width: none; /* Firefox */
}


.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.divisaoHr{
    margin: 0;
    padding: 0;
    height: 1px; /* Define a altura da linha */
    border: none;
    background-color: #ccc
}


.flexContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}



.threeOptions {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   gap: 20px;
}


.op{
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease; /* Define a transição suave */
    width: 130px;
    height: 130px;
}

.op:hover {
    border: 5px solid rgba(153, 253, 231, 0.678);
    transform: scale(1.05); /* Aumenta o tamanho em 20% */
}



.fadeBackground {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    top: 0;
    left: 0;
}

.iconVoltar{
    cursor: pointer;
}





@media only screen and (max-width: 600px) { /*@PAREADO COM O SHOWMODALCRIARSHORTS E SHOWMODALCRIARPOSTAGEM*/
    .modalPostagem{
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        max-height: 100vh;
    }
}



 @media only screen and (max-width: 450px) { /*@PAREADO COM O SHOWMODALCRIARSHORTS E SHOWMODALCRIARPOSTAGEM*/
    .threeOptions {
        flex-direction: column;
     }

     .op{
        width: 150px;
        height: 150px;
     }
     
}