*{
    box-sizing: border-box;
}
.modalPostagem {
    position: fixed;
    z-index: 4;
    top: calc(50vh - 225px);
    right: calc(50vw - 275px);
    width: 550px;
    height: 450px;
    box-shadow: 0 0 10px black;
    background-color: white;
    border-radius: 3px;
    overflow: auto; /* Permite rolagem */
    scrollbar-width: none; /* Firefox */
}



.modalShorts::-webkit-scrollbar {
    display: none; /* Chrome, Safari e outros navegadores Webkit */
  }

.loadingImg {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loadingImg img {
    width: 100%;
}

.loadingImg p {
    font-weight: bold;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.divisaoHr{
    margin: 0;
    padding: 0;
    height: 1px; /* Define a altura da linha */
    border: none;
    background-color: #ccc
}
.iconSend {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.iconSend:hover {
    background-color: #0056b3;
}

hr {
    margin-top: 8px;
    border-color: rgba(117, 117, 117, 0.39);
}

.flexContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.flexSuperior{
    flex: 0 0 auto;
}
.imgPost{
    width: 100px;
}

.flexInferiorModal1 {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
}

textarea {
    width: 100%;
    height: 200px;
    resize: none;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
}

textarea:focus {
    outline: none;
}

.link{
    width: 100%;
    margin-bottom: 5px;
}

.flexInferiorModal2{
    padding: 5px;
}

.divSelectedMidia {
    position: relative;
    width: calc((100% / 5) - 10px); /*Menos 10 px, isto é, 5 de margem de cada lado*/
    aspect-ratio: 1;
    margin: 5px;
    overflow: hidden;
}
.buttonContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.escolherPublico{
    min-width: 120px;
}

.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.trashIcon {
    width: 26px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    filter: brightness(200%) contrast(150%) saturate(200%);
}



.imagePreview:hover {
    filter: brightness(50%);
    cursor: pointer;
}

.imagePreview:hover + .trashIcon {
    width: 40px;
    left: 20px;
    top: 18px;
    filter: brightness(500%);
}

.fadeBackground {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    top: 0;
    left: 0;
}

.buttonSelectMidia {
    margin-top: 10px;
    padding: 6px;
    background-color: #007bff;
    border: 0;
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.buttonSelectMidia:hover {
    background-color: #006cdf;
    transition: 0.4s;
}

.error {
    color: red;
}
.iconVoltar{
    cursor: pointer;
}


@media only screen and (max-width: 600px) {  /*@PAREADO COM O SHOWMODALCRIARSHORTS E SHOWMODALCRIARALGO*/
    .modalPostagem{
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        max-height: 100vh;
    }
}