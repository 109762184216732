/*.modalDelete{
    position: absolute;
    z-index: 5;
}*/

.tipoDenuncia{
    font-weight: bold;
    color:red;
}
.limitaCarousel{
    max-height: 650px;
    overflow: hidden;
}
    .postagem {
        margin-top: 50px;
        width: 100%;
    }
    
    .hrQuebraPostagem{
        margin-top: 15px;
        border-color:rgba(128, 128, 128, 0.205);
    }
    .cursorPointer{
        cursor: pointer;
    }

    .postagemHeader {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 10px;
    }
    
    .postagemHeaderImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
    .nameAndUsername{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 5px;
    }
    .postagemUserName{
        font-size: 12px;
    }
    .postagemName {
        font-weight: bold;
        cursor: pointer;
    }
    .modalSpan{ /*Tirar isso irá causar problema ao exibir modal do perfil*/
        position: relative;
    }
    
    .modalSpan:hover{
        height: 20px; /*Trick pra n dar espaço entre nome e modal e acabar saindo do modal sem qrer*/
    }
    .modalStyle{
        position: absolute;
        background-color: white;
        height: 280px;
        width: 350px;
        z-index: 20;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        left: 10px;
        top: 17px; /* Ajuste para a posição desejada */
    }
    
    .postagemHorario {
        font-size: 15px;
        color: grey;
        margin-left: 3px;
    }
    .deletarPostagem{
        margin-left: auto;
        max-height: 100%;
        font-size: 30px;
        cursor: pointer;
    }
    
    .postagemTimeIcon {
        color: grey;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: 8px;
    }
    
    .postagemCorpo {
        background-color: rgb(0, 0, 0);
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
    }
    
    .interacoes {
        font-size: 28px;
        display: flex;
        justify-content: flex-start;
        gap: 15px;
        margin: 8px;
    }
    
    .opcoesModerador{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
    }

    .opcoesModerador button{
        padding:10px;
    }
    .liberar {
        background-color: rgb(10, 255, 10);
        color: white;
        border: 0;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }
    
    .warning {
        background-color: orange;
        color: white;
        border: 0;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }
    
    .excluirPostagem {
        background-color: red;
        color: white;
        border: 0;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }
    
    .banirUsuario {
        background-color: black;
        color: white;
        border: 0;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
    }
    

    /*Modal confirmar*/
    .fadeBackgroundModal{
        position: fixed;
        background-color: rgba(8, 8, 8, 0.226);
        height: 100vh;
        width: 100vw;
        top:0;
        right: 0;
        z-index: 3;
    }
    .containerModal{
        position: fixed;
        height: 100vh;
        width: 100vw;
        top:0;
        right: 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .parteUsada {
        z-index: 4;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        height: 180px;
        width: 300px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        text-align: center;
    }
    .titulo{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .icon{
        cursor: pointer;
    }
    .titulo p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }
    
    .parteUsada p {
        font-size: 16px;
        color: #555;
    }
    
    /*.button {
        background-color: #f44336;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 20px;
    }*/
    
    @media only screen and (max-width: 480px) {
        /*(FEITO) Postagens mudou pra ocupar 100% da tela, concertando perfil e descricao (adicionando padding) */
        .postagemHeader{
            padding: 0 10px;
        }
        .descricaoPostagem{
            padding-left: 10px;
        }
    }
    