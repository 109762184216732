.container {
    z-index: 5;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    left:0;
    top:0;
}
  .fadeBackground{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.562);
    z-index: 1;
}

.containerVerificarPerfil {
  background-color: #f9f9f9; /* Fundo suave para destaque */
  border: 2px solid #0073e6; /* Borda com cor azul para chamar atenção */
  border-radius: 10px; /* Bordas arredondadas para um visual moderno */
  padding: 20px; /* Espaçamento interno */
  max-width: 400px; /* Largura máxima para centralizar o conteúdo */
  margin: 20px auto; /* Centraliza horizontalmente na página */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidade */
  font-family: 'Arial', sans-serif; /* Fonte limpa e fácil de ler */
}

.cobranca{
  color:rgb(51, 51, 51);
}

.containerVerificarPerfil p::before {
  content: '✔ '; /* Adiciona um ícone de check para todos os parágrafos */
  color: green; /* Cor verde para indicar confirmação/benefício */
}

.containerVerificarPerfil p {
  color: #333; /* Cor de texto legível */
  font-size: 16px; /* Tamanho de texto para boa leitura */
  line-height: 1.5; /* Espaçamento entre as linhas */
}

.title {
  font-weight: bold; /* O primeiro parágrafo em negrito */
  font-size: 18px; /* Tamanho ligeiramente maior para destaque */
}

.textoDeletarConta {
  text-align: justify;
  font-family: Arial, sans-serif;
  line-height: 1.6; /* Espaçamento entre linhas para melhor leitura */
  font-size: 16px; /* Tamanho da fonte adequado para leitura */
  color: #333; /* Cor do texto, um cinza escuro para não ser muito pesado */
  margin: 20px 0; /* Espaçamento em volta do parágrafo */
  padding: 10px; /* Espaçamento interno para dar mais "respiro" */
  border-radius: 5px; /* Bordas arredondadas */
  border-left: 4px solid #007BFF; /* Adiciona uma linha colorida na lateral esquerda para destaque */

}
.deletarButton{
  padding:5px;  
  color:white;
  background-color: red;
  border: 1px solid #e0e0e0; /* Borda muito suave para um efeito sutil */
  border-radius: 8px; /* Bordas arredondadas para um visual mais moderno */
  padding: 12px 16px; /* Um pouco mais de padding para melhorar o toque */
  cursor: pointer;
  width: 100%;
}
  
  .modal {
    z-index: 2;
    background-color: white;
    width: 500px;
    height: 420px;
    padding: 20px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .menuLateral {
    width: 150px;
    border-right: 1px solid #ddd;
    padding-right: 10px;
  }
  
  .menuBtn {
    background-color: white;
    border: 1px solid #e0e0e0; /* Borda muito suave para um efeito sutil */
    border-radius: 8px; /* Bordas arredondadas para um visual mais moderno */
    padding: 12px 16px; /* Um pouco mais de padding para melhorar o toque */
    cursor: pointer;
    text-align: left;
    margin-bottom: 8px;
  }
  
  .menuBtn:hover {
    background-color: rgba(119, 119, 119, 0.116);
    transition: 0.5s;
  }
  
  /*.menuBtn:active {
    box-shadow: inset 0 2px 4px rgba(252, 1, 1, 0.1); /* Efeito de profundidade quando clicado 
     }
  */

  .selectBox {
    border: 1px solid #e0e0e0; /* Borda suave */
    border-radius: 8px; /* Bordas arredondadas */
    padding: 10px 14px; /* Padding para espaçamento interno */
    width: 100%; /* Largura completa do contêiner */
    transition: border-color 0.3s, box-shadow 0.3s; /* Transições suaves */
  }
  

 
  .conteudo {
    flex: 1;
    padding-left: 20px;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none; /*Esconde rolagem internet explorer e edge*/
  }

  
  .conteudo::-webkit-scrollbar{
    display: none; /*Esconde a barra de rolagem no Chrome, Safari e Edge */
  }
 .fieldSetDeletarConta{
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-color: rgba(128, 128, 128, 0.342);
    gap:15px
 }
 .googleButton{
  padding:5px;  
  color:white;
  background-color: green;
  border: 1px solid #e0e0e0; /* Borda muito suave para um efeito sutil */
  border-radius: 8px; /* Bordas arredondadas para um visual mais moderno */
  padding: 12px 16px; /* Um pouco mais de padding para melhorar o toque */
  cursor: pointer; 
 }
 /*.containerDeletar{
  overflow-y: auto;
  max-height: 100%;
  margin-bottom: 8px;
  -ms-overflow-style: none;  // Esconder barra no Internet Explorer e Edge 
 }
 
 .containerDeletar::-webkit-scrollbar {
  display: none; // Esconde a barra de rolagem no Chrome, Safari e Edge 
}
*/

  
  .section {
    display: flex;
    flex-direction: column;
    gap:10px;
  }

  
  
  input, select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
  }
  
  .updateBtn {
    padding: 10px;
    background-color: #ffffff;
    color: black;
    border: 0.5px solid rgb(209, 209, 209);
    border-radius: 4px;
    cursor: pointer;
    margin-top:10px;
  }
  
  .updateBtn:hover {
    background-color: rgba(119, 119, 119, 0.116);
    transition: 0.5s;
  }
  
  .containerVoltar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:7px;
    align-items: center;
    margin-bottom: 8px;
  }
  .botoes{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:5px;
  }

  .googleButton[disabled] {
    background-color: rgba(173, 173, 173, 0.589);
  }


@media only screen and (max-width: 550px) { 
  .modal{
      width: 100vw;
      height: 100vh;
      border-radius: 0;
  }
}



@media only screen and (max-width: 480px) { 
  .modal{
    flex-direction: column;
  }
  .menuLateral{
    width: 100%;
    padding:0;
  }
  .botoes{
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    width: 100%;
  }
  .botoes button{
    flex:1 1 100%;
  }
  .containerVoltar{
    margin-bottom: 10px;
  }

  .menuLateral{
    border:0;
    flex-direction: row;
    gap:10px;
    /*width: 100%;*/
    flex-wrap: wrap;
  }
  .conteudo{
    padding: 0;
  }
}

.errMessageRed{
  color:red;  
}
.verificarButton {
  padding: 5px;
  background-color: #007bff; /* Cor de fundo básica */
  color: white; /* Cor do texto */
  border: none; /* Remove a borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
  transition: background-color 0.3s; /* Transição suave para a mudança de cor */
}

.verificarButton:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}