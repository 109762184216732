.divVideo {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 90vh; /* Ajuste conforme necessário */
    aspect-ratio: 9 / 16; /* Mantém a proporção */
    scroll-snap-align: center;
}

.video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover; /* Mantém a proporção sem distorcer o vídeo */
}

.playButton, .muteButton {
    position: absolute;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1; /* Garante que os botões fiquem acima do vídeo */
}

.playButton {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.iconeMute{
   background-color: rgba(0, 0, 0, 0.404);
   border-radius: 50%; 
   padding: 5px;
}

.muteButton {
    top: 10px;
    right: 10px;
}


.postagemHeader {
    position: absolute;
    bottom: 7px;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 10px;
    gap: 5px;
}

.postagemHeaderImg {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    object-fit: cover;
}



.postagemUsername {
    color: white;
    text-shadow: 1px 1px 2px #000;
}

.containerInteracoes {
    position: absolute; /* Alinha com base no contêiner pai */
    bottom: 80px;
    right: 0;
    height: calc( 100% - 80px );
    display: flex;
    flex-direction: column-reverse;
    font-size: 30px;
    padding: 10px; /* Adiciona padding para não colar no canto */
    box-sizing: border-box; /* Inclui padding e border na largura e altura do elemento */
}

.interacoes{
    display: flex;
    flex-direction: column;
    gap:25px;
    align-items: center;
}
.interacoesIconContainer{
    background-color: rgba(0, 0, 0, 0.404);
    border-radius: 50%; 
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
}
.icon{
    color:white;
    cursor: pointer;
}
.postadorInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    cursor: pointer;
    color:rgb(255, 255, 255);
}
.buttonSeguir{
    background-color: rgba(0, 0, 0, 0.281);
    border: 1px solid rgb(255, 250, 250);
    border-radius: 6px;
    color:black;
    background-color: white;
    padding:7px;
    cursor: pointer;
    box-shadow: 0px 0px 2px rgb(0, 0, 0);
}

.progressBarContainer{
    position: absolute;
    bottom: 7px;
    width: 100%;
    height: 10px;
}
.progressFilled{
    height: 15px;
    background-color: red;
    position: absolute;
    bottom:-7px;
    left: 0;
    pointer-events: none;
    margin:0;
    padding: 0;
}

.progressBar{ /*Estiliza progress bar*/
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    bottom:0px;
    appearance: none;
    background:rgba(83, 83, 83, 0.514);
    border-radius:0;
    cursor: pointer;
    border:0px;
    width: 100%;
    height: 15px;
    margin:0;
    padding: 0;
}

.progressBar::-webkit-slider-thumb { /*Estilo da bolinha*/
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff0000;
    z-index: 2;
    cursor: pointer; 
  }

  .progressBar::-moz-range-thumb { /*Para firefox stilo bolinha*/
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff0000;
    z-index: 2;
    cursor: pointer; 
  }

@media only screen and (max-width: 650px) {
    .divVideo {
        height: calc( 100vh - 38px); /*subtraindo menu bottom*/
        scroll-snap-align: start;
    }
}

@media only screen and (max-width: 450px) {
    .divVideo {
        width: 100vw;
    }
}

