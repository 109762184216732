.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  background-color: white;
  align-items: center;
  padding-left: 10px;
}

.NotificacaoNumeroShort {
  background-color: red;
  font-size: 14px;
  border-radius: 20px;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  left: 21px;
  top: 7px;
}

.NotificacaoNumeroShort2{
  background-color: red;
  font-size: 14px;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  position: absolute;
  left: 25px;
  top:4px;
}

.divIconShort {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.icon {
  font-size: 25px;
}

.dropdownMenu {
  top: 30px;
  left: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100vw;
  height: calc(100vh - 30px);

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hevelim{
  font-family: 'Poppins';
  font-size: 1.5em;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
}

.divIconShort {
  display: flex;
  gap: 5px;
}

.line {
  height: 3px;
  background-color: #000;
  border-radius: 5px;
}


.rowFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 5px 0;
  position: relative;
}

.notificacoesContainer {
  font-size: 25px;
  margin: 0;
  padding: 0;
}

.dropdownMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdownMenu li {
  margin-bottom: 10px;
}

.dropdownMenu li a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
}

.dropdownMenu li:last-child {
  margin-bottom: 0;
}