.bibleSelector {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
  }
  
  label {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .dropdown {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-top: 5px;
    background-color: #f9f9f9;
  }
  
  .selectedBook {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
  
  .separator {
    font-style: italic;
    text-align: center;
    background-color: #e9e9e9;
    padding: 5px;
    color: #555;
  }
  
  @media (max-width: 600px) {
    .bibleSelector {
      margin: 10px;
    }
  
    .dropdown {
      font-size: 14px;
    }
  }
  