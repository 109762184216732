.divVideo {
    position: relative;
    max-height: 600px;
    overflow: hidden;
    background-color: black;
    display: flex;
    justify-content: center;
}

.video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.playButton, .muteButton {
    position: absolute;
    color: white;
    font-size: 2rem; /* Ajuste o tamanho conforme necessário */
    cursor: pointer;
    z-index: 2; /* Garante que os botões fiquem acima do vídeo */
}

.playButton {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.muteButton {
    top: 10px; /* Posiciona no canto superior direito */
    right: 10px;
    z-index:0;
}

.iconeMute{
    background-color: rgba(0, 0, 0, 0.473);
    border-radius: 50%; 
    padding: 5px;
}

.progressBarContainer{
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 10px;
}
.progressFilled{
    height: 15px;
    background-color: red;
    position: absolute;
    bottom:-7px;
    left: 0;
    pointer-events: none;
    margin:0;
    padding: 0;
}

.progressBar{ /*Estiliza progress bar*/
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    bottom:0px;
    appearance: none;
    border-radius: none;
    background:rgba(83, 83, 83, 0.514);
    border-radius:0;
    cursor: pointer;
    border:0px;
    width: 100%;
    height: 15px;
    margin:0;
    padding: 0;
}

.progressBar::-webkit-slider-thumb { /*Estilo da bolinha*/
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff0000;
    z-index: 2;
    cursor: pointer; 
  }

  .progressBar::-moz-range-thumb { /*Para firefox stilo bolinha*/
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff0000;
    z-index: 2;
    cursor: pointer; 
  }


 




