.limitaCarousel {
    max-height: 650px;
    overflow: hidden;
}

.postagem {
    width: 100%;
    margin-bottom: 20px;
}

.cursorPointer {
    cursor: pointer;
}


.containerInteracoes {
    position: absolute;
    z-index: 2;
    background-color: white;
    bottom: 0;
    right: -50px;
}


.modalStyle {
    position: absolute;
    background-color: white;
    height: 280px;
    width: 350px;
    z-index: 20;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    left: 10px;
    top: 17px;
    /* Ajuste para a posição desejada */
}

.postagemHorario {
    font-size: 15px;
    color: grey;
    margin-left: 3px;
}

.deletarPostagem {
    margin-left: auto;
    max-height: 100%;
    font-size: 30px;
    cursor: pointer;
}

.postagemTimeIcon {
    color: grey;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 8px;
}

.postagemCorpo {
    background-color: rgb(0, 0, 0);
    width: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

.fadeBackground {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.233);
    z-index: 4;
}

.errorMessage {
    font-size: 1.05em;
    /* Tamanho da fonte um pouco maior que o padrão */
    color: #d9534f;
    /* Cor vermelha para destacar a mensagem de erro */
    background-color: #f8d7da;
    /* Fundo rosa claro para destacar a mensagem */
    border: 1px solid #f5c6cb;
    /* Borda rosa claro para complementar o fundo */
    padding: 10px;
    /* Espaçamento interno para melhorar a legibilidade */
    border-radius: 5px;
    /* Bordas arredondadas para um visual mais suave */
    margin: 10px 0;
    /* Margem para separar a mensagem de outros elementos */
    line-height: 1.4;
    /* Espaçamento entre linhas para uma leitura mais fácil */
    white-space: pre-line;
    /* Suporta quebras de linha e espaços em branco */
}

/* Home Page Styles */

.container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-areas: "espaco-esquerdo espaco_central";
    overflow: auto;
    background-color: rgb(255, 255, 255);
    scroll-snap-type: y mandatory;
}


.fimPostagens {
    margin: 20px 0 30px 0;
}

.divGift {
    width: 100%;
    display: flex;
    justify-content: center;
}

.gifLoading {
    z-index: 5;
    margin-top: 20px;
    height: 50px;
    width: 50px;
}

.espaco_esquerdo {
    grid-area: espaco-esquerdo;
    /*PS: Tamanho do menu lateral é exatamente 250px, q é definido no grid*/
    max-width: 100%;
}

.espaco_central {
    padding-top: 20px;
    grid-area: espaco_central;
    display: flex;
    justify-content: center;
}

.postagens {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 70px;
}

.espaco_direito {
    grid-area: espaco-direito;
    display: flex;
    justify-content: flex-start;
    padding: 40px 0 0 0;
    flex-direction: column;
}

/*
    
    /* Modal Styles */
.modalPostagem {
    position: fixed;
    z-index: 3;
    top: calc(50vh - 200px);
    right: calc(50vw - 200px);
    width: 400px;
    max-height: 400px;
    box-shadow: 0 0 10px black;
    background-color: white;
    border-radius: 7px;
    padding: 8px;
    overflow: hidden;
    overflow: auto;
}

.loadingImg {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loadingImg img {
    width: 100%;
}

.loadingImg p {
    font-weight: bold;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconSend {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.iconSend:hover {
    background-color: #0056b3;
}

hr {
    margin-top: 8px;
    border-color: rgba(117, 117, 117, 0.39);
}

/* Textarea Styles */
textarea {
    width: 100%;
    height: 200px;
    resize: none;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
}

textarea:focus {
    outline: none;
}


/* Media Selection */
.selectedMidia {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.divSelectedMidia {
    position: relative;
    width: 81px;
    height: 81px;
    margin: 5px;
}

.trashIcon {
    width: 26px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    filter: brightness(200%) contrast(150%) saturate(200%);
}

.imagePreview {
    width: 100%;
    height: 100%;
}

.imagePreview:hover {
    filter: brightness(50%);
    cursor: pointer;
}

.imagePreview:hover+.trashIcon {
    width: 40px;
    left: 20px;
    top: 18px;
    filter: brightness(500%);
}

/* Button Styles */
.buttonSelectMidia {
    margin-top: 10px;
    padding: 6px;
    background-color: #007bff;
    border: 0px;
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.buttonSelectMidia:hover {
    background-color: #006cdf;
    transition: 0.4s;
}

/* User Little Profile */
.showuserlittleprofile {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.showuserlittleprofile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.showuserlittleprofileData {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.showuserlittleprofileName {
    font-weight: bold;
    font-size: 15px;
}

.showuserlittleprofileArroba {
    font-size: 14px;
    color: #3f3f3f;
}


/* Estilos para telas menores (mobile) */
@media only screen and (max-width: 1120px) {
    .container {
        grid-template-columns: 250px 1fr;
        grid-template-areas: "espaco-esquerdo espaco_central";
    }

    .espaco_direito {
        display: none;
    }

    .postagens {
        padding-right: 0px;
    }
}

@media only screen and (max-width: 880px) {
    .container {
        grid-template-columns: 70px 1fr;
        /*Menu short ocupa 70px apenas*/
        grid-template-areas: "espaco-esquerdo espaco_central";
    }

    .espaco_direito {
        display: none;
        /* Esconde o espaco_direito */
    }
}


@media only screen and (max-width: 480px) {
    .postagens {
        width: 100%;
    }
}


@media only screen and (max-width: 650px) {
    .espaco_central {
        padding-top: 0;
        background-color: black;
    }
    .postagem {
        width: 100%;
        margin-bottom: 0px;
    }
    .container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "espaco_central";
        /*N precisa do espaço esquerdo pq ele fica em cima do central (fixed)*/
    }
    .espaco_esquerdo {
        position: fixed;
        /* Fixa o menu na parte inferior */
        bottom: 50px;
        width: 100vw;
        /* Ocupa toda a largura da tela */
        z-index: 2;
    }
}

