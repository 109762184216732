.container{
    display: grid;
    grid-template-columns: 250px 1fr;
    overflow: auto;
    height: 100vh;
    grid-template-areas: "espaco_esquerdo espaco_central";
}
.settings {
    display: inline-block;
    padding: 10px 20px;
    height: 40px;
    background-color: #e0e0e0d5; /* Cor de fundo */
    color: rgb(99, 98, 98); /* Cor do texto */
    font-size: 15px; /* Tamanho da fonte */
    font-weight: bold;
    border: none; /* Remove borda */
    border-radius: 5px; /* Borda arredondada */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Animação suave */
}
.flexRow{
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: fit-content;
    align-items: center;
}

.settings:hover {
    background-color: #45a049; /* Cor de fundo ao passar o mouse */
}

.settings:active {
    background-color: #3e8e41; /* Cor de fundo ao clicar */
    transform: scale(0.98); /* Efeito de "pressionado" */
}

.settings:focus {
    outline: none; /* Remove o contorno padrão */
    box-shadow: 0 0 5px rgba(72, 207, 173, 0.8); /* Sombra quando focado */
}

.cursorPointer{
    cursor: pointer;
}



.espaco_central{
    grid-area: espaco_central;
    display: flex;
    justify-content: center;
    width: 100%;
}
.espacoUsado{
    width:90%;
    max-width: 900px;
    margin-top:30px;
}

.borderContainer {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 5px;
    box-shadow: 0 0 0 6px rgba(0,0,0,0.1); /* Adiciona uma borda visível */
    cursor: pointer;
  }
  
  .profileImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    display: block; /* Remove espaços indesejados ao redor da imagem */
  }

.lineOneContainer{
    display: flex;
}

.rightSideFromImg{
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:20px;
}
.perfilInexistente{
    display: flex;
    justify-content: center;
}
.stats{
    display: flex;
    flex-direction: row;
    gap:50px;
}
.organizador{
    display: flex;
    flex-direction: row;
    gap:20px;
}
.leftSide{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.nomeSobrenome{
    font-size: 20px;
}

/* botão */
.followButton {
    height: 30px; /* Ajustei a altura para uma melhor aparência */
    padding: 0 20px; /* Espaçamento horizontal */
    border: none; /* Remove a borda padrão */
    border-radius: 20px; /* Bordas arredondadas para um visual moderno */
    background: linear-gradient(45deg, #3498db, #2980b9); /* Gradiente azul */
    color: white; /* Cor do texto */
    font-family: 'Poppins', sans-serif; /* Fonte estilizada */
    font-weight: 600; /* Negrito para o texto */
    font-size: 14px; /* Tamanho do texto */
    text-transform: uppercase; /* Texto em maiúsculas */
    cursor: pointer; /* Cursor de pointer para indicar clicável */
    transition: background 0.3s ease, transform 0.2s ease; /* Transições suaves */
    display: flex;
    align-items: center;
    justify-content: center; /* Centraliza o texto dentro do botão */
}

/* Estilo para quando o botão é focado */
.followButton:focus {
    outline: none; /* Remove o contorno padrão de foco */
}

/* Estilo para quando o botão é pressionado */
.followButton:active {
    transform: scale(0.98); /* Efeito de clique */
}

/* Estilo para quando o botão é hover (passa o mouse sobre ele) */
.followButton:hover {
    background: linear-gradient(45deg, #2980b9, #3498db); /* Inverte as cores do gradiente para efeito de hover */
}

.statsMobile{
    display: flex;
    justify-content: center;
    gap:20%;
    height: 30px;
    align-items: center;
    margin: 5px 0;
    padding: 0 20px;
}

.statsMobileHr{
    border:1px solid rgba(0, 0, 0, 0.075);
}

.statsMobileSpan{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

@media only screen and (max-width: 950px) { /*Adaptacao 1 - reorganizando as coisas*/
    .borderContainer{ /*Diminuindo imagem em 50px*/
        width:150px;
        height: 150px;
    }
    .stats{
        gap:20px;
    }
}


@media only screen and (max-width: 880px) {
    .container{
        grid-template-columns: 70px 1fr;
        grid-template-areas: "espaco_esquerdo espaco_central";
    }
    .lineOneContainer{
        margin-bottom: 15px;
    }
    .organizador{
        flex-direction: column;
    }
    .statsMobile{
        font-size: 0.8em;
    }
    .espacoUsado{
        width:100%
    }
    .lineOneContainer{
        justify-content: center;
    }
    .leftSide{
        gap:10px;
    }

}


@media only screen and (max-width: 650px) {
    .container{
        grid-template-columns: 100%;
        grid-template-areas: "espaco_central";
        padding-top: 20px;
    }
    .menu_lateral{
        position: fixed; /* Fixa o menu na parte inferior */
        bottom: 50px;
        width: 100vw; /* Ocupa toda a largura da tela */
        z-index:2;
    }
    .userNamePerfil{
        font-size: 0.8em;
    }
    .followButton{
        font-size: 0.7em;
    }
    .nomeSobrenome{
        font-size: 1em;
    }
    .borderContainer{
        height: 130px;
        width: 130px;
    }
}
