.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    padding:20px;
  }


 