.modalShorts {
    position: fixed;
    z-index: 4;
    top: calc(50vh - 225px);
    right: calc(50vw - 275px);
    width: 550px;
    height: 450px;
    box-shadow: 0 0 10px black;
    background-color: white;
    border-radius: 3px;
    overflow: hidden;
    
    overflow: auto; /* Permite rolagem */
    scrollbar-width: none; /* Firefox */
  
}
.flexContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
}

.buttonContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.iconVoltar{
    cursor:pointer;
}

.flexInferiorModal1 {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
}
.flexInferiorModal2{
    padding: 5px;
}

.flexSuperior{
    flex: 0 0 auto;
}

  .modalShorts::-webkit-scrollbar {
    display: none; /* Chrome, Safari e outros navegadores Webkit */
  }

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.divisaoHr{
    margin: 0;
    padding: 0;
    height: 1px; /* Define a altura da linha */
    border: none;
    background-color: #ccc
}

.iconSend {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.iconSend:hover {
    background-color: #0056b3;
}

.buttonSelectMidia {
    margin-top: 10px;
    padding: 6px;
    background-color: #007bff;
    border: 0;
    font-size: 18px;
    color: white;
    cursor: pointer;
}

.buttonSelectMidia:hover {
    background-color: #006cdf;
    transition: 0.4s;
}

.error {
    color: red;
}


.loadingImg {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loadingImg img {
    width: 100%;
}

.videoContainer {
    width: 100px;
    height: 100px;
}
  
.playPauseButton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    cursor: pointer;
    z-index: 10; /* Garante que o botão esteja acima do vídeo */
}

.textAreaDescricao{
    margin-top: 7px;
}


@media only screen and (max-width: 600px) { /*@PAREADO COM O SHOWMODALCRIARALGO*/
    .modalShorts{
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        max-height: 100vh;
    }
    .textAreaDescricao{
        height: 25vh;
    }
}

@media only screen and (max-width: 400px) {
    .textAreaDescricao{
        height: 20vh;
    }
}