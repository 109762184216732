
.searchModal{ 
    position:absolute;
    background-color: white;
    width:400px;
    height:100vh;
    margin-left:70px; 
    z-index: 3;
    border-radius: 10px;
    padding:20px;
    overflow-y: auto;
}
.mensagemDenuncia{
    text-align: justify;
}
.searchModalTitle{ 
    font-size: 1.4em;
    text-align: center;
    margin-bottom: 25px;
}
.searchModalContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap:5px;
}
.showmore {
    color: blue;
    cursor: pointer; /* Adiciona um cursor de ponteiro para indicar que é clicável */
    font-size: 1em;  /* Ajusta o tamanho da fonte se necessário */
    text-align: center; /* Alinha o texto no centro, se desejado */
    margin-top: 20px; /* Adiciona margem superior para separar do conteúdo acima */
}

.noNotifications {
text-align: center;
color: #999;
margin-top:5px;
}

.grupoButton{
    display: flex;
    justify-content: space-between;
    gap:7px;
}
.botaoTop{ /*Todos botoes*/
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: rgb(49, 49, 49);
    cursor: pointer;
}
.botaoTopSelected { /*Botao selecionado*/
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: #c4f1fd;
    color: rgb(49, 49, 49);
    cursor: pointer;
}


.notificationItem {
padding: 10px 0;
border-bottom: 1px solid #eee;
}
.msgNoMore{
    text-align: center;
}

.icon {
color: #ff4757;
margin-right: 8px;
}


.flexContainer{
    display: flex;
    flex-direction: column;
}


.corpoNot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.thumbnailContainer{
    cursor: pointer;
}
.thumbnail{
    width: 70px;
    height: 70px;
}
.nomeDataNot{
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    max-width: calc(100% - 60px);
    margin: 5px 0;
}
.iconNotific{
    font-size: 20px;
    display: flex;
    position: absolute;
    right: -60px;
}
.novo{
    width: 60px;
}

.descricaoPostagem{
    color:rgb(54, 54, 54);
    margin-bottom: 15px;
}

.iconVoltar {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 580px) {
    .searchModal { 
        position: fixed;
        width: 100vw;
        height: 100vh;
        margin: 0;
        z-index: 3;
        top: 0;
        left: 0;
        padding: 30px;
        border-radius: 0;
    }
}
