.buttonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabButton {
    background: transparent;
    border: none;
    border-bottom: 2px solid transparent; /* Linha de sublinhado invisível por padrão */
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.3s, border-bottom 0.3s;
}

.todasPostagens{
    display: flex;
    flex-direction: column;
    width: 468px;
}

.tabButton.active {
    color: #3498db; /* Cor do texto quando ativo */
    border-bottom: 2px solid #3498db; /* Linha de sublinhado azul quando ativo */
}

.tabButton:hover {
    color: #2980b9; /* Cor do texto ao passar o mouse */
}

/* Estilos para o conteúdo das abas */
.content {
    display: flex;
    justify-content: center;
    padding: 20px 20px 20px 0;
}

.contentTab {
    display: none; /* Inicialmente escondido */
    max-width: 100%;
}

.contentTab.active {
    display: block; /* Mostra o conteúdo da aba ativa */
}
.divGift{
    width: 100%;
    display: flex;
    justify-content: center;
}
.gifLoading{
    height: 30px;
    width: 30px;
}
.endMessageDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    margin:20px 0 20px 0;
}

.containerFlex{
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
    max-width: 100%;
    gap:10px;
}
.spanContainer{
    flex: 1 1 calc(33.333% - 10px);
    max-width: 270px;
    
}
.postagemImg{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover; /* Ajusta a imagem para cobrir a caixa sem distorcer */
    cursor: pointer;
}

.postagemVideo{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover; /* Ajusta a imagem para cobrir a caixa sem distorcer */
    cursor: pointer;
}


@media only screen and (max-width: 880px) {
    .containerFlex{
        display: flex;
        justify-content: center;
    }
    .content{
        padding: 0;
    }

}


@media only screen and (max-width: 400px) {
    .tabButton{
        font-size: 0.8em;
    }
}

@media only screen and (max-width: 480px) { /*Mobile pequeno, ocupa tela toda nas postagens*/
    /*(FEITO) Postagens mudou pra ocupar 100% da tela */
    .todasPostagens{
        width: 100%;
    }
}