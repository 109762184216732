/*.modalDelete{
    position: absolute;
    z-index: 5;
}*/
.limitaCarousel{
max-height: 650px;
overflow: hidden;
}
.postagem {
    margin-top: 30px;
    width: 100%;
}
.ytLinkDiv{
    width: 100%;
}
.hrQuebraPostagem{
    margin-top: 15px;
    border-color:rgba(128, 128, 128, 0.205);
}
.cursorPointer{
    cursor: pointer;
}

.postagemHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.postagemHeaderImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.nameAndUsername{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    text-wrap: nowrap;
}

.postagemUserName{
    font-size: 12px;
}
.postagemName {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    gap: 5px;
}

.modalSpan{ /*Tirar isso irá causar problema ao exibir modal do perfil*/
    position: relative;
}

.modalSpan:hover{
    height: 20px; /*Trick pra n dar espaço entre nome e modal e acabar saindo do modal sem qrer*/
}
.modalStyle{
    position: absolute;
    background-color: white;
    z-index: 20;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    left: 10px;
    top: 17px; 
}


.postagemHorario {
    font-size: 15px;
    color: grey;
    margin-left: 3px;
}
.deletarPostagem{
    margin-left: auto;
    max-height: 100%;
    font-size: 30px;
    cursor: pointer;
}

.postagemTimeIcon {
    color: grey;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 8px;
}

.postagemCorpo {
    background-color: rgb(0, 0, 0);
    width: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}

.interacoes {
    font-size: 28px;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin: 8px;
}





@media only screen and (max-width: 480px) {
    /*(FEITO) Postagens mudou pra ocupar 100% da tela, concertando perfil e descricao (adicionando padding) */
    .postagemHeader{
        padding: 0 10px;
    }
    .descricaoPostagem{
        padding-left: 10px;
    }
}
