.comentario{
    margin-top:12px;
  }

  .comentarioUpper{
    flex: 1 1 90%;
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: flex-start;
  }

  .imgPost, .imgComents{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  .nomeEComentario{
    width: 100%;
    position: relative;
    margin-bottom: 17px;
  }

  .nomeComentarista{
    font-weight: bold;
    padding-right: 10px;
  }

  .usernameComentarista{
    font-size: 12px;
  }

  .atributosComentario{
    display: flex;
    align-items: center;
    gap:20px;
    margin-top:5px;
    font-weight: bold;
    color:rgba(128, 128, 128, 0.685);
  }

  .hr{
    margin:50px 0;
  }
  .deletado{
    color:red;
  }
  .botoesJulgamento{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
    flex-wrap: wrap;
  }
 
  .botoesJulgamento button{
    flex: 1 1 100%;
    padding: 8px;
    cursor: pointer;
    font-size: 1.09em;
  }
  .abrirPost{
    padding: 7px;
    color:white;
    border:0px;
    background-color: rgb(0, 238, 255);
    border-radius: 8px;
    cursor: pointer;
  }


  .btnLiberar {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btnWarning {
    background-color: orange;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btnDeletar {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .closeIcon{
    font-size: 1.5em;
    cursor: pointer;
  }
  .containerTitle{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .btnBanir {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  

  
  .opcaoContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    background-color: white;
    z-index: 4;
    min-width: 250px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}

.fadeBackground{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.39);
  top:0;
  left: 0;
  z-index: 3;
}
.opcaoTitle{
  text-align: center;
  font-weight: bold;
}
.bold{
  font-weight: bold;
  font-size: 1.09em;
}