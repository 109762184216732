/* Container principal do carrossel */
.containerImgsPost {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: black;
}

/* Imagem da publicação */
.postagemCorpoImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    user-select: none; /* Impede a seleção da imagem */
}

/* Controladores do carrossel (setas de navegação) */
.controladoresCarousel {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.iconeSeta {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Sombra suave ao redor */
    border-radius: 50px;
}

/* Setas de navegação */
.arrowCarousel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15%;
    font-size: 30px;
    color: white;
    cursor: pointer;
}

.arrowCarousel:hover {
    /*background-color: rgba(119, 117, 117, 0.212);*/
}

/* Div para abrir o modal */
.divOpenModalPostagem {
    width: 100%;
}


/*
@media only screen and (max-width: 800px) {
   .postagemCorpoImg{
        max-height: 90vh;
   }
  
}
   */