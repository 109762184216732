*{
  box-sizing: border-box;
}

.replyContainer{
  width: fit-content;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.replyShow{
  margin-top: 15px;
}
/* .exibirVidYt{
background-color: black;
height: 100%;
width: 100%;
max-width: 500px;
} */
.nomeEGlobo{
  display: flex;
  align-items: center;
}
.replySpan hr{
  width: 30px;
  border: 1px solid rgb(112, 112, 112);
}


  .container{
    height: auto;
    width: 100%;
    margin-bottom: 15px;

  }
  .nomeEComentario{
    width: 100%;
    position: relative;
    margin-bottom: 17px;
  }
  
  .tresPontos{
    font-size: 2em;
    vertical-align: top;
    position: absolute;
    line-height: 1px;
    color: grey;
    cursor: pointer;
    right: 0;
    top:0;
  }
  .tresPontos:hover{
    color:black;
  }
  .atributosUserMaisDeletePost{
    display: flex;
    justify-content: space-between;
  }
  .deletePostagemDiv{
    flex: 1 1 10%;
  }
 
  .modalContainer {
    width: 100vw;
    height: 100vh;
    z-index: 5; /* Z-index alto para garantir que esteja acima de outros conteÃšdos */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .modalContent {
    position: absolute;
    background-color: white;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index:7;
    height:97vh;
    max-width: 95vw;
  }

  .sessaoComentario{
    /*display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 35px;
    grid-template-areas: "scrollfull" "partefixa";
    padding: 20px 20px 5px 20px;*/
    width: 450px;
    min-width: 400px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }


  .limitaCarousel{
    height: 100%;
    background-color: black;
  }

  .limiteVideoYt{
    min-width: 500px;
    height: 100%;
    background-color: black;
    padding: 5px;
  }
  
  
  .nomeUserN{
    margin-bottom: 5px;
  }

  /*Comentarios*/

  .comentarioUpper{
    flex: 1 1 90%;
    display: flex;
    flex-direction: row;
    gap:12px;
    align-items: flex-start;
  }
  .imgPost, .imgComents{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
  .exibirMais{
    cursor: pointer;
  }
  .cursorPointer{
    cursor: pointer;
  }
  .ameiButton{
    cursor: pointer;
    font-size: 20px;
  }
  .nomeEDescricaoPost{
    position: relative;
    width: 100%;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .nomeComentarista{
    font-weight: bold;
    padding-right: 10px;
  }
  .usernameComentarista{
    font-size: 12px;
  }
  .showMoreComments{
    height:20px;
    cursor: pointer;
  }
  .spanComentDisp{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap:5px;
    padding: 3px 3px 0 0;
    color: rgb(92, 91, 91);
  }
  
 
  .atributosComentario{
    display: flex;
    align-items: center;
    gap:20px;
    margin-top:5px;
    font-weight: bold;
    color:rgba(128, 128, 128, 0.685);
  }
  .botaoCurtir{
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .fadeBackground{
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro transparente para modal */
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .separarComentarios{
    position: relative;
    border-color: rgba(5, 5, 5, 0.24);
    border-radius: 8px;
  }

  .comentario{
    margin-top:12px;
  }



  .scrollFull{
    max-height: 100%;  
    overflow-y: auto;
    
  }

  .scrollFull::-webkit-scrollbar{
    width: 0;
    background-color: transparent;
  }

 .parteFixa{
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap:5px;
 }

 .imageReplyContainer{
  color:white;
  border-radius: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;

 }
 .closeIcon{
  position: absolute;
  pointer-events: none;
 }
 
.imageReply{
  max-width: 100%;
  border-radius: 50px;
}

 .parteFixaInput{
  flex: 1 1 80%;
  border: 0;
  height: 100%;
 }
 .parteFixaButton {
  background-color: #007bff; /* Azulzinho */
  flex: 1 1 20%;
  color: #fff; /* Texto branco */
  border: none; /* Sem borda */
  height: 100%; /* Altura ocupando todo o espaÃ§o disponÃ­vel */
  font-size: 14px; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
  padding: 10px; /* EspaÃ§amento interno */
  cursor: pointer; /* Cursor ao passar */
  transition: background-color 0.3s ease; /* TransiÃ§ÃĢo suave da cor de fundo */
}

.parteFixaButton:hover {
  background-color: #0056b3; /* Azulzinho mais escuro ao passar */
}




/*Video*/
.divVideo{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.video{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.playButton, .muteButton {
  position: absolute;
  color: white;
  font-size: 2rem; /* Ajuste o tamanho conforme necessÃĄrio */
  cursor: pointer;
  z-index: 2; /* Garante que os botÃĩes fiquem acima do vÃ­deo */
}
.playButton {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.muteButton {
  top: 10px; /* Posiciona no canto superior direito */
  right: 10px;
}

.mobileUpperComments{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: 600;
}

.progressBarContainer{
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 10px;
}
.progressFilled{
  height: 15px;
  background-color: red;
  position: absolute;
  bottom:-7px;
  left: 0;
  pointer-events: none;
  margin:0;
  padding: 0;
}

.progressBar{ /*Estiliza progress bar*/
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  bottom:0px;
  appearance: none;
  border-radius: none;
  background:rgba(83, 83, 83, 0.514);
  border-radius:0;
  cursor: pointer;
  border:0px;
  width: 100%;
  height: 15px;
  margin:0;
  padding: 0;
}

.progressBar::-webkit-slider-thumb { /*Estilo da bolinha*/
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff0000;
  z-index: 2;
  cursor: pointer; 
}

.progressBar::-moz-range-thumb { /*Para firefox stilo bolinha*/
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff0000;
  z-index: 2;
  cursor: pointer; 
}


@media only screen and (max-width: 1000px) {
  .limiteVideoYt{
    min-width: auto;
  }

}


@media only screen and (max-width: 800px) {
  /*Menor q 800 o comentario pega tela inteira, entÃĢo a midia fica em cima*/

  .modalContent{
    max-width: 100vw;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
  }
  .sessaoComentario{
    width: 100vw;
  }
  .midias{
    max-width: 100%;
  }

  .sessaoComentario{
    min-width: auto;
  }
  .limitaCarousel{
    max-height: 90vh;
  }

}