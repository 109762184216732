
/*MARCAÇÕES*/
.marcacao {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%; /* Define uma largura de 90% para telas menores */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centraliza o elemento em relação ao centro da tela */
  margin: 0; /* Remove margens desnecessárias */
  z-index: 5;
  width: 500px;
  height: 350px;
}

.semMarcacoes {
  text-align: center;
  padding: 15px;
  background-color: #f5f5f5;
  color: #999;
  border: 1px dashed #ddd;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.fadeBackground{
  background-color: rgba(0, 0, 0, 0.363);
  width: 100vw;
  height: 100vh;
  left: 0;
  top:0;
  position: fixed;
  z-index: 4;
}

.voltarContainer{
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  justify-content: space-between;
}

.setaVoltar{
  cursor: pointer;
  font-size: 1.3em;
}
  
  .marcacao h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .marcacoesContainer {
    max-height: 400px; /* Ajuste a altura máxima conforme necessário */
    overflow-y: auto; /* Permite o scroll quando necessário */
  }
  
  .marcacaoItem {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
  }
  
  .marcacaoInfo p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
  }
  
  .marcacaoActions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .acessarBtn {
    background-color: #007bff;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .acessarBtn:hover {
    background-color: #0056b3;
  }
  
  .deleteBtn {
    background-color: #ff4d4f;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .deleteBtn i {
    font-size: 16px;
  }
  
  .deleteBtn:hover {
    background-color: #d12f2f;
  }
  
  .expandBtn {
    background-color: #28a745;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .expandBtn:hover {
    background-color: #218838;
  }
  
  .marcacaoDetails {
    padding: 10px;
    margin-top: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
  }
  
  .marcacaoItem + .marcacaoItem {
    margin-top: 15px;
  }
  
  

  @media only screen and (max-width: 650px) { /*Mobile pequeno, ocupa tela toda nas postagens*/
    /*(FEITO) Postagens mudou pra ocupar 100% da tela */
    .marcacao{
        width: 100vw;
        height: 100vh;
        border-radius: 0;
    }
  }
  
  