
.flexGoogle {
  margin-top:20px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}


.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:5px 10px;
  gap:10px;
  background-color: white; /* Azul do Google */
  color: black;
  border: 1px solid rgba(170, 170, 170, 0.151);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif; /* Alteração da fonte */
}
.googleButton:hover {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.googleIco{
  width: 30px;
}




.container {
  text-align: center;
  padding: 15px 15px 15px 15px;
  background-color: white;
  box-shadow: 0 0 5px rgba(75, 75, 75, 0.432);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.loginRegisterHeader {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  gap: 10px;
}

.loginRegisterHeader button { /*Botoes superiores ativos ou não*/
  width: 100%;
  margin: 0 0 10px 0;
  padding: 10px 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  background-color: white;
}

.active {
  border: none;
  color: black;
  box-shadow: 0 4px 5px -4px rgba(0, 238, 255, 0.616); /* Sombra apenas no bottom */
}

.notactive {
  border: none;
  color: gray; /* Cor do texto levemente cinza para indicar inatividade */
}

.form {
  input:not([type="checkbox"]) {
    margin-bottom: 5px;
    height: 50px;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 17px;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
}
.checkBoxDiv{
  display: flex;
  align-items: center;
  gap:10px;
  padding: 7px;
  color: rgb(78, 78, 78);
}

.link {
  color: #007BFF; /* Azul vibrante */
  text-decoration: none; /* Remove o sublinhado padrão */
  font-weight: bold; /* Texto em negrito */
  font-size: 16px; /* Tamanho da fonte */
  transition: color 0.3s ease; /* Transição suave para a cor */
  cursor: pointer;
}

.link:hover {
  color: #0056b3; /* Azul mais escuro no hover */
  text-decoration: underline; /* Sublinhado no hover */
}



.entrar {
  background-color: rgb(166, 237, 248);
  border: 0;
  color: rgb(0, 0, 0);
  cursor:pointer;
  margin-top: 5px;
  height: 45px;
  width: 100%;
  border-radius: 20px; /* Bordas suavizadas */
  font-size: 17px;
  font-family: 'Roboto', sans-serif; /* Fonte estilizada */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.2); /* Sombra leve no bottom */
}
.entrar:hover{
  background-color: rgb(117, 231, 248);
  transition: 0.7s;
}

.loginError {
  color: rgba(250, 41, 41, 0.801);
  margin: 5px;
}

