/* style.module.css */

.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    overflow-y: auto;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    margin: 10px 0;
  }
  
  .sidebar a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .sidebar a:hover {
    color: #007BFF;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  .content p{
    line-height: 1.6;
    color: #5a5a5a;
  }
  
  .section {
    margin-bottom: 40px;
  }
  .voltarLi{
    cursor: pointer;
    font-weight:bold;
    color:green;
    display: flex;
    align-items: center;
    gap:2px;
  }
  .container h2 {
    border-bottom: 2px solid #007BFF;
    padding-bottom: 10px;
    margin-bottom: 20px;
    color: #333;
  }
  .title{
    font-weight: bold;
  }
  
 
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      box-shadow: none;
    }
  
    .content {
      padding: 10px;
    }
  }
  