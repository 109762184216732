.showuserlittleprofile {
    display: flex;
    flex-direction: row;
}
.showuserlittleprofile{
    cursor: pointer;
}

.showuserlittleprofile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.showuserlittleprofileData {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}
.flexNomeImg{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.flexNomeImg img{
    width: 20px;
    height: 20px;
}

.showuserlittleprofileName {
    font-weight: bold;
    font-size: 15px;
}

.showuserlittleprofileArroba {
    font-size: 14px;
    color: #3f3f3f;
}
